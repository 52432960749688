@font-face {
  font-family: "John";
  src: url("./Louize.ttf") format("truetype");
}

body {
  background: #fff;
  max-width: 100vw;
  margin: 0;
  padding: 0px;
  position: relative;
}

@media only screen and (max-width: 900px) {
  body {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.gap {
  height: 160px;
}

@media only screen and (max-width: 900px) {
  .gap {
    height: 120px;
  }
}

.small-gap {
  height: 80px;
}

@media only screen and (max-width: 900px) {
  .small-gap {
    height: 80px;
  }
}

.nav-bar {
  display: inline-block;
  width: 100%;
}

.navLogo {
  height: 40px;
  padding: 40px;
}

@media only screen and (max-width: 900px) {
  .navLogo {
    padding-left: 0px;
  }
}

.get-notified {
  float: right;
  margin: 34px;
  padding: 16px 12px 16px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  color: #292a2c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
}

.get-notified:hover {
  background: #f3f4f6;
}

@media only screen and (max-width: 900px) {
  .get-notified {
    margin-right: 0px;
  }
}

.hero-container {
  background: url("./BGImages/plants.jpg");
  background-size: 100%;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 900px) {
  .hero-container {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    background-size: cover;
  }
}

.headline {
  font-size: 140px;
  line-height: 119px;
  font-family: John;
  color: #fff;
  z-index: 100;
  text-align: center;
  letter-spacing: -0.3rem;
  margin-top: 48px;
  margin-bottom: 64px;
}

@media only screen and (max-width: 900px) {
  .headline {
    font-size: 70px;
    line-height: 64px;
    font-family: John;
    /* color: #40473F; */
    color: #fff;
    z-index: 100;
    text-align: center;
    letter-spacing: -0.2rem;
    margin-bottom: 48px;
  }
}

.intro-container {
  padding: 100px 80px;
  background: #f3f4f6;
  max-width: calc(1025px - 160px);
  margin: 0 auto;
  border-radius: 16px;
  /* margin-top: 80px; */
}

@media only screen and (max-width: 900px) {
  .intro-container {
    padding: 64px 40px;
    background: #f3f4f6;
    max-width: 100vw;
    margin: 0 auto;
    border-radius: 16px;
    /* margin-top: 48px; */
  }
}

.intro-container-light {
  padding: 80px 80px;
  background: url("./BGImages/tree.jpg");
  background-size: 100%;
  max-width: calc(1025px - 160px);
  margin: 0 auto;
  border-radius: 16px;
  /* margin-top: 80px; */
}

@media only screen and (max-width: 900px) {
  .intro-container-light {
    padding: 40px 40px;
  }
}

.intro-headline {
  font-family: "John";
  font-weight: 300;
  color: #292a2c;
  font-size: 58px;
  line-height: 68px;
  letter-spacing: -0.04em;
}

@media only screen and (max-width: 900px) {
  .intro-headline {
    font-family: "John";
    font-weight: 300;
    color: #292a2c;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.04em;
  }
}

.light-headline {
  color: white;
}

@media only screen and (max-width: 900px) {
  .light-headline {
    line-height: 100%;
  }
}

.gradient {
  text-align: center;
}

.logomark {
  width: 48px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 900px) {
  .logomark {
    width: 32px;
    margin-bottom: 24px;
  }
}

.small-headline {
  font-size: 100px;
  line-height: 90%;
  font-family: John;
  color: #292a2c;
  z-index: 100;
  text-align: center;
  letter-spacing: -0.3rem;
  /* margin-top: 120px; */
  margin-bottom: 32px;
}

@media only screen and (max-width: 900px) {
  .small-headline {
    font-size: 64px;
    line-height: 90%;
    font-family: John;
    color: #292a2c;
    z-index: 100;
    text-align: center;
    letter-spacing: -0.2rem;
    margin-bottom: 32px;
  }
}

.center-paragraph {
  max-width: 650px;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #1d1d1f;
  margin-bottom: 48px;
}

@media only screen and (max-width: 900px) {
  .center-paragraph {
    max-width: 90%;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
    margin-bottom: 48px;
  }
}

.upper {
  z-index: 2;
}

.lower {
  z-index: 0;
  margin-top: 20px;
  filter: blur(16px) hue-rotate(10deg);
}

.laptop {
  width: 80%;
  max-width: 1025px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  display: block;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .laptop {
    width: 100%;
    max-width: 100vw;
    height: 100%;
  }
}

.video {
  border: 1px solid rgba(153, 153, 153, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
}

.video-inner {
  margin-top: -1px;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .video-inner {
    margin-top: -1px;
    width: 150%;
    margin-left: -20%;
  }
}

.container {
  display: flex;
  margin: auto;
  max-width: 1025px;
  background: #f3f4f6;
  border: 1px solid #f3f4f6;
  overflow: hidden;
  border-radius: 20px;
}

@media only screen and (max-width: 900px) {
  .container {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

.text-container {
  max-width: 50%;
  padding: 80px;
}

@media only screen and (max-width: 900px) {
  .text-container {
    padding: 32px;
  }
}

.left {
  text-align: left;
}

.text-container .small-headline {
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -0.1rem;
  margin-top: 24px;
}

.text-container .center-paragraph {
  font-size: 19px;
  line-height: 27px;
}

@media only screen and (max-width: 900px) {
  .left {
    text-align: left;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .text-container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .text-container .center-paragraph {
    max-width: 100%;
    margin-bottom: 0px;
  }
}

.right {
  margin-left: 24px;
  width: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 900px) {
  .right {
    margin-left: auto;
    width: 100%;
    object-fit: contain;
  }
}

.switch-container {
  background: #f3f4f6;
  padding: 48px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  border-radius: 16px;
  max-width: calc(1025px - 64px);
  display: block;
  margin: 0 auto;
}

.switch-nav {
  width: fit-content;
  display: block;
  margin: auto;
  padding-bottom: 40px;
}

.switch-nav-element {
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.switch-nav-element:hover {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.coming-soon {
  background: white;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 100px;
  color: #00b08b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
}

.signup-form-container {
  display: flex;
  margin: 0 auto;
  width: fit-content;
}

.signup-form-input {
  padding: 8px 12px;
  border: 2px solid #dadce0;
  font-size: 16px;
  margin-left: 8px;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgb(15 15 15 / 10%);
  font-family: Inter;
  font-weight: 500;
  outline: #00b08b;
  -webkit-appearance: none;
}

.signup-form-input:focus-visible {
  border: 2px solid #00b08b;
}

.signup-form-input:focus {
  border: 2px solid #00b08b;
}

.signup-form-button {
  padding: 8px 12px;
  background: #292a2c;
  color: #fff;
  border: 2px solid black;
  margin-left: 8px;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgb(15 15 15 / 10%);
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
  -webkit-appearance: none;
}

@media only screen and (max-width: 900px) {
  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .mobile {
    display: none;
  }
}

.signup-form-button:hover {
  box-shadow: 0 4px 4px rgb(15 15 15 / 10%);
}

.signup-hint {
  font-family: Inter;
  color: #292a2c;
  opacity: 0.8;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  display: block;
  margin-top: 16px;
}

.footer-container {
  width: auto;
  border-top: 1px solid #dadce0;
  padding: 40px;
}

.footer-inner-container {
  width: fit-content;
  display: flex;
}

.footer-inner-container {
  width: fit-content;
  display: flex;
  margin: 0 auto;
}

.footer-span {
  margin-right: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #292a2c;
}

.footer-span a {
  color: #292a2c;
}